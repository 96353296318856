import PushNotificationsLayout from "layouts/PushNotificationsLayout";
import { Routes } from "./routes";
import { getGlobalWorkspace } from "axiosConfig";
import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { lazyRetry } from "utils/routerUtils";
const LoginForm = lazy(() =>
  lazyRetry(() => import("features/Login/LoginFormSubpage"), "LoginFormSubpage"),
);
const ResetPassword = lazy(() =>
  lazyRetry(() => import("features/Login/ResetPasswordSubpage"), "ResetPasswordSubpage"),
);
const SetNewPassword = lazy(() =>
  lazyRetry(() => import("features/Login/CreateNewPasswordSubpage"), "SetNewPassword"),
);
const AccountDetails = lazy(() =>
  lazyRetry(() => import("features/Registration/AccountDetails"), "AccountDetails"),
);
const EmailVerification = lazy(() =>
  lazyRetry(() => import("features/Registration/EmailVerification"), "EmailVerification"),
);
const SignUp = lazy(() => lazyRetry(() => import("features/Registration/SignUp"), "SignUp"));
const Login = lazy(() => lazyRetry(() => import("pages/Login"), "Login"));
const Home = lazy(() => lazyRetry(() => import("pages/Home"), "Home"));
const Registration = lazy(() => lazyRetry(() => import("pages/Registration"), "Registration"));
const Project = lazy(() => lazyRetry(() => import("pages/Project"), "Project"));
const ProjectDetails = lazy(() =>
  lazyRetry(() => import("pages/ProjectDetails"), "ProjectDetails"),
);
const AccountSettings = lazy(() =>
  lazyRetry(() => import("pages/AccountSettings"), "AccountSettings"),
);
const SuccessAuthPage = lazy(() =>
  lazyRetry(() => import("pages/Login/SuccessAuthPage"), "SuccessAuthPage"),
);
const Kanban = lazy(() => lazyRetry(() => import("pages/Kanban"), "Kanban"));
const OrgSettings = lazy(() =>
  lazyRetry(() => import("pages/OrganizationSettings"), "OrgSettings"),
);
const Page404 = lazy(() => lazyRetry(() => import("pages/404"), "404"));
const ScreenRequirementsPage = lazy(() =>
  lazyRetry(() => import("pages/ScreenRequirements"), "ScreenRequirements"),
);
const ScreenReqsNewPage = lazy(() =>
  lazyRetry(() => import("pages/ScreenReqsNew"), "ScreenReqsNew"),
);
const BacklogPage = lazy(() => lazyRetry(() => import("pages/Backlog"), "Backlog"));
const workspaceId = getGlobalWorkspace();

const loggedWorkspace = localStorage.getItem("workspace");

export const pagesRouter = createBrowserRouter([
  {
    element: <PushNotificationsLayout />,
    children: [
      {
        path: Routes.default,
        errorElement: <Page404 />,
        element: <Navigate to={`/${workspaceId ?? loggedWorkspace}/${Routes.home}`} />,
      },
      {
        path: `:workspaceId/${Routes.home}`,
        errorElement: <Page404 />,
        element: <Home />,
      },
      {
        path: `:workspaceId/${Routes.project}/:projectId`,
        errorElement: <Page404 />,
        element: <Project />,
      },
      {
        path: `:workspaceId/${Routes.project}/:projectId/project-details/:tab`,
        errorElement: <Page404 />,
        element: <ProjectDetails />,
      },
      {
        path: `:workspaceId/${Routes.project}/:projectId/:screenId`,
        errorElement: <Page404 />,
        element: <ScreenReqsNewPage />,
      },
      {
        path: `:workspaceId/${Routes.project}/:projectId/:screenId/:taskId`,
        errorElement: <Page404 />,
        element: <ScreenReqsNewPage />,
      },
      {
        path: `:workspaceId/${Routes.kanban}`,
        errorElement: <Page404 />,
        element: <Kanban />,
      },
      {
        path: `:workspaceId/${Routes.kanban}/:groupId`,
        errorElement: <Page404 />,
        element: <Kanban />,
      },
      {
        path: `:workspaceId/${Routes.kanban}/:sprintId`,
        errorElement: <Page404 />,
        element: <Kanban />,
      },
      {
        path: `:workspaceId/${Routes.backlog}`,
        errorElement: <Page404 />,
        element: <BacklogPage />,
      },
      {
        path: `:workspaceId/${Routes.kanban}/:groupId/:screenId`,
        errorElement: <Page404 />,
        element: <ScreenReqsNewPage />,
      },
      {
        path: `:workspaceId/${Routes.kanban}/:groupId/:screenId/:taskId`,
        errorElement: <Page404 />,
        element: <ScreenReqsNewPage />,
      },
      {
        path: `:workspaceId/${Routes.settings}`,
        errorElement: <Page404 />,
        element: <AccountSettings />,
      },
      {
        path: `:workspaceId/${Routes.organizationSettings}`,
        errorElement: <Page404 />,
        element: <OrgSettings />,
      },

      {
        path: Routes.figmaAuth,
        errorElement: <Page404 />,
        element: <SuccessAuthPage />,
      },
      {
        path: Routes.notFound,
        errorElement: <Page404 />,
        element: <Page404 />,
      },
    ],
  },
  {
    path: Routes.login,
    errorElement: <Page404 />,
    element: <Login />,
    children: [
      {
        path: Routes.current,
        errorElement: <Page404 />,
        element: <LoginForm />,
      },
      {
        path: Routes.resetPassword,
        errorElement: <Page404 />,
        element: <ResetPassword />,
      },
      {
        path: Routes.createNewPassword,
        errorElement: <Page404 />,
        element: <SetNewPassword />,
      },
    ],
  },
  {
    path: Routes.registration,
    errorElement: <Page404 />,
    element: <Registration />,
    children: [
      {
        path: Routes.current,
        errorElement: <Page404 />,
        element: <SignUp />,
      },
      {
        path: Routes.emailVerification,
        errorElement: <Page404 />,
        element: <EmailVerification />,
      },
      {
        path: Routes.accountDetails,
        errorElement: <Page404 />,
        element: <AccountDetails />,
      },
    ],
  },
  {
    path: "*",
    element: <Page404 />,
  },
]);
