import { createSlice } from "@reduxjs/toolkit";
import { ENTITY_TYPES } from "constants/projects";
import { ChatState } from "store/types/chat";

const initialState: ChatState = {
  isOpen: false,
  isModalOpen: false,
  oldValue: "",
  field: "",
  messages: [],
  isLoading: false,
  chatResponse: {
    entity: ENTITY_TYPES.screen,
    field: "",
    llm_message: "",
    slug: "",
    value: "",
  },
};

export const chatSlice = createSlice({
  name: "Chat",
  initialState,
  reducers: {
    setIsOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setMessages: (state, { payload }) => {
      state.messages = [...state.messages, payload];
    },
    setIsModalOpen: (state, { payload }) => {
      state.isModalOpen = payload;
      state.isOpen = false;
    },
    setOldValue: (state, { payload }) => {
      state.oldValue = payload;
    },
    setChatResponse: (state, { payload }) => {
      state.chatResponse = payload;
    },
    setField: (state, { payload }) => {
      state.field = payload;
    },
  },
});

export const {
  setIsOpen,
  setIsModalOpen,
  setOldValue,
  setIsLoading,
  setMessages,
  setField,
  setChatResponse,
} = chatSlice.actions;

export default chatSlice.reducer;
